/*
 * Responsive text aligning
 * http://ohryan.ca/2014/08/14/set-responsive-text-alignment-bootstrap-3/
 */
.center-xs-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@media (min-width: $screen-sm-min) {
  .center-xs-block {
    margin: none;
  }
  .center-sm-block {
    margin-left: auto;
    margin-right: auto;
      display: block;
  }
  .center-md-block {
    margin: none;
  }
  .center-lg-block {
    margin: none;
  }
}
@media (min-width: $screen-md-min) {
  .center-xs-block {
    margin: none;
  }
  .center-sm-block {
    margin: none;
  }
  .center-md-block {
    margin-left: auto;
    margin-right: auto;
      display: block;
  }
  .center-lg-block {
    margin: none;
  }
}
@media (min-width: $screen-lg-min) {
  .center-xs-block {
    margin: none;
  }
  .center-sm-block {
    margin: none;
  }
  .center-md-block {
    margin: none;
  }
  .center-lg-block {
    margin-left: auto;
    margin-right: auto;
      display: block;
  }
}


/* ==================== */
/* === Modify Class === */
/* ==================== */


.btn-block{
  padding: 12px 8px;
  font-size: 16px;
}

.short-hr{
  padding: 0 9em;
}

/* ==================== */
/* === Agent === */
/* ==================== */

.agent-divider{
width:60%;
}
@media (min-width: $screen-sm-min) {
  .agent-divider{
    width: 60%;
  }
}
@media (min-width: $screen-md-min) {
  .agent-divider{
    width:80%;
  }
}
@media (min-width: $screen-lg-min) {
  .agent-divider{
    width:100%;
  }
}
