/*-----------------------------------*/


/*-- Component Clients --*/


/*-----------------------------------*/

.authors {
  margin: 30px 0;
  h3 {
    margin: 0;
  }
  p {
    text-align: justify;
    a {
      color: $moss;
    }
  }
}

.client {
  .book-list {
    h4 {
      font-weight: normal;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        width: 100%;
      }
    }
  }
  .find-out-more {
    margin: 40px 0;
    h4 {
      font-weight: normal;
    }
    .box {
      background-color: $grain_brown;
      padding: 30px;
    }
  }
  h4 {}
}

.list-type-tag {
  margin: 10px 0;
  padding: 0;
  span {
    padding: 5px 10px;
    background-color: #EEEAE0;
    border-radius: 4px;
    margin-right: 5px;
    text-transform: capitalize;
    font-size: 18px;
  }
}

.portrait-img {
  border: 4px solid #cccccc;
  margin-bottom: 10px;
}

.search-for-author {
  input {
    height: 50px;
    font-family: "proxima-nova", sans-serif;
    font-style: italic;
    padding: 10px 15px;
    margin: 0px !important;
  }
  button {
    background-color: $mine_shaft;
    border-radius: 4px;
    color: white;
    padding: 10px 15px;
    border: 0px;
    width: 15%;
    float: right;
    height: 50px;
  }
  p {
    span {
      color: $moss;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.box {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 15px;
      margin: 0 0 10px 0;
      padding: 0px;
      i {
        margin-right: 10px;
      }
      a {
        color: $mine_shaft;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

.social-page {
  h2 {
    text-align: center;
    font-family: "proxima-nova", sans-serif;
    font-weight: 200;
  }
  h3 {
    text-align: center;
    font-family: "proxima-nova", sans-serif;
    font-weight: 200;
  }
  .gallery {
    // margin-bottom: 30px;
    #gallery {
      // height: 500px;
      // background-color: lightgray;
    }
  }
  .socialhub {
    .social {
      .social-wrap {
        margin-bottom: 50px;
        position: relative;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        height: 400px;
        padding-bottom: 20px;
        border: 1px solid lightgray;
        padding: 5px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        // background-color: lightgray;
        li {
          margin: 0;
          padding: 10px;
          background-color: white;
          border: 1px solid lightgray;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 10px;
          text-align: justify;
          a {
            font-weight: 100;
          }
        }
      }
      .main-social {
        overflow: hidden;
      }
      div {}
      a.social-a {
        color: black;
        text-align: center;
        position: absolute;
        bottom: 30px;
        left: 50%;
        border: 0;
        padding: 10px;
        background-color: lightgray;
        border-radius: 6px;
        width: 150px;
        margin-left: -75px;
        text-decoration: none;
      }
    }
  }
}
