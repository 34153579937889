@charset "utf-8";
/* CSS Document */

/* xs */
/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	.mainfooter .nav {
		text-align: center;
	}
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* sm */
/* Small Devices, Tablets */
@media only screen and (min-width : $screen-sm-min) {

}

/* md */
/* Medium Devices, Desktops */
@media only screen and (min-width : $screen-md-min) {
	.mainfooter .nav {
		text-align: left;
	}
}

/* lg */
/* Large Devices, Wide Screens */
@media only screen and (min-width : $screen-lg-min) {

}

/*-----------------------------------*/
/*-- Global Styles, Fonts, and Bootstrap Overrides --*/
/*-----------------------------------*/

body, html {
	font-size: 16px !important;
	font-family: "proxima-nova",sans-serif;
	line-height: 1.6em;
}

.full-width {
	padding: 0px !important;
}

.font-secondary, var, cite, blockquote, q, dfn, input {
	font-family: 'Amiri', Georgia, serif;
	font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Amiri', Georgia, serif;
	color: #000000;
	font-weight: bold;
}

h5 {
	font-size: 1.5em;
	font-style: italic;
	font-weight: normal;
}

h6 {
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: .1em;
}

a {
	color: #009045;
	font-weight: bold;
}

a:hover {
	color: #004b29;
}

a:visited {
	color: #267242;
	text-decoration: underline;
}

.calltoaction {
	background-image:url(../img/leaf2.png);
	background-repeat: no-repeat;
	background-position: 0px 22px;
	padding: 20px;
	margin: 20px;
	font-size: 1.1em;
	overflow: visible;
}

blockquote, .well blockquote {
	border-color: #a8c7b3;
	font-size: 1.2em;
}

small {
	font-size: .9em;
}

del {
	color: #999999;
}

ins {
	font-weight: bold;
	color: #2a86ca;
	text-decoration: none;
	background: #FAF3D0;
}

mark {
	background: #F0DB72;
}

dfn {
	font-weight: normal;
	font-style: normal;
	text-transform: uppercase;
	font-size: 1.2em;
}

code {
	color: #009045;
	background: #F0F6F3;
}


/*-----------------------------------*/
/*-- Header and Navigation Styling --*/
/*-----------------------------------*/

nav {
	font-family: 'Amiri', Georgia, serif;
	font-size: 24px;
}

.hero {
	color: #fff;
 	background: url(../img/prospect_park_2.jpg) #222 fixed repeat-y;
	padding: 3em 1em 3em 1em;
	text-align: center;
	border-top: .7vh solid #267242;
 	margin: 0;
	height: 15em;
 }

 .hero h1 {
 	color: #fff;
	font-weight: normal;
	font-family: 'Amiri', Georgia, serif;
	font-size: 2.25em;
	font-style: italic;
	}

.diamonds {
	width: .5em;
	margin: 0 .5em 0 .5em;
}

.header-frame {
	background: url(../img/header-frame.svg) center center no-repeat;
	text-align: center;
	height: 140px;
	padding-top: 28px;
	padding-right: 0px;
	padding-left: 0px;
}

.hero hr {
	border-top: 1px solid #fff;
}

.hero hr:after {
	content: '';
	display: block;
	margin-top: 4px;
	border-top: 1px solid #fff;
}

.subheader {
	background: url(../img/diamond-background-shadowed.png) #292625 center repeat-x;
	border-bottom: .7vh solid #c8b4a0;
	margin: 0;
	text-align: center;
	padding: 2.3em;
	height: 6em;
}

.subheader h2 {
	font-weight: normal;
	font-size: 1.38em;
	padding: 0;
	margin: 0;
	color: #fff;
}

@media (max-width: 500px) {
	.subheader {
		padding: 1.8em;
	}
	.subheader h2 {
		font-size: 1.2em;
	}
}

.container-fluid {
	padding: 28px 28px 18px 28px;
}

.navbar {
	margin-bottom: 0;
}

.navbar-brand {
	padding: 8px 0px 12px 0px;
	height: 30px;
}

.navbar-right img {
	vertical-align: top;
	padding-right: 5px;
}

/* Reset typography on navigation */
.navbar-default .navbar-nav > li > a {
	color: #000;
	font-weight: normal;
	}

/* Overrides negative Bootstrap borders on brand image */

.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
	margin: inherit;
}

@media (max-width: 600px) {
	.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
		margiN: 0px;
	}
}


/* Collapse Navbar For Tablet (sooner than default) */
@media (max-width: 1100px) {
	.navbar-header {
		float: none;
	}
	.navbar-left,.navbar-right {
		float: none !important;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	}
	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
	.navbar-collapse.collapse {
		display: none!important;
	}
	.navbar-nav {
		float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
		float: none;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.collapse.in{
  		display:block !important;
	}

}

/* Decreases size of brand image so it doesn't hit the collapsed menu on smaller screens */
@media (max-width: 600px) {
	.navbar-brand img {
		width: 100%;
	}
	.navbar-brand {
		width: 290px;
		margin-right: 10px;
	}
}


/*-----------------------------------*/
/*-- Home Page Header Styling -------*/
/*-----------------------------------*/

.hero-home {
	color: #fff;
//	background: url(../img/prospect-park-still.jpg) no-repeat 50% 80%;
	background-size:cover;
	position:relative;
	height: 80vh;
 	text-align: center;
	border-top: 1vh solid #333;
	border-bottom: 1vh solid #c8b4a0;
	padding: 0px !important;
}

.hero-shadow {
	background-color: rgba(20,20,20,0.25);
	height: 78vh;
}

.hero-wrapper {
	background: url(../img/hero-graphic.svg) 50% 30% no-repeat;
	background-size: 75% 75%;
	padding-top: 22vh;
	height: inherit;
 }

.hero-home h1 {
	 color: #ecf2f0;
	 font-size: 3.6em;
	 font-weight: normal;
}

.hero-home h2 {
	 color: #fff;
	 font-size: 1.5em;
	 font-weight: normal;
	 padding-left: 25%;
	 padding-right: 25%;
	 line-height: 1.5em;
}
@media (max-width: 800px) {
	.hero-home h1 {
		font-size: 2.6em;
		padding-left: 10%;
		padding-right: 10%;
	}
	.hero-home h2 {
	 	font-size: 1.25em;
		padding-left: 20%;
		padding-right: 20%;
	}
}
@media (min-width: 1500px) {
	.hero-home h2 {
		padding-left: 35%;
		padding-right: 35%;
	}
}

/*-----------------------------------*/
/*-- Color Styles --*/
/*-----------------------------------*/

.sg-colors {
	overflow: hidden;
}
.sg-colors li {
	overflow: hidden;
	border: 1px solid #dddddd;
	padding: 0.3em;
	margin: 0 0.2em 0.2em 0;
}
@media all and (min-width: 30em) {
	.sg-colors li {
		float: left;
		width: 5em;
	}
}

.sg-swatch {
	display: block;
	height: 1.5em;
	width: 50%;
}
@media all and (max-width: 30em) {
	.sg-swatch {
		float: left;
		margin-right: 0.3em;
	}
}
@media all and (min-width: 30em) {
	.sg-swatch {
		width: 100%;
		height: 4em;
		margin-bottom: 0.2em;
	}
}

.styleguide-header {
	display: block;
	border-bottom: 1px solid gray;
	padding: 2em 0 1em 0;
	margin-bottom: 1em;
}

/*-----------------------------------*/
/*-- Strengths --*/
/*-----------------------------------*/

.strengths {
	padding: 100px 20px 100px 20px;
	text-align: center;
}

.strengths div {
	padding: 10px;
}


.strengths img {
	width: 65px;
	height: 65px;
	vertical-align: middle;
	text-align: center;
}

.strengths h3, .strengths h2, .strengths p {
	font-weight: normal;
	color: #333;
	line-height: 1.8em;
}

.strengths p {
	font-family: "proxima-nova",sans-serif;
 	font-size: 1.3em;
	text-align: left;
	padding: 10px;
}

.centersingleline {
	margin-top: 0.2em;
} /*Hack to vertically center single line text on icons */

@media (min-width: 1500px) {
	.strengths h3, .strengths h2, .strengths p  {
		font-size: 2em;
	}

	.strengths p {
		font-size: 1.5em;
	}
	.centersingleline {
		margin-top: 3px;
	} /*Hack to center text on icons */

}


/*-----------------------------------*/
/*-- Bookshelf --*/
/*-----------------------------------*/

.bookshelf {
	padding: 60px 40px 80px 40px;
	background: url(../img/diamond-pattern.svg) #333 center fixed;
}

.bookshelf h3 {
	color: #fff;
	font-weight: normal;
	font-style: normal;
	padding-bottom: 10px;
	font-size: 1.75em;
}

.bookshelf h3 img {
	width: 65px;
	height: 65px;
	margin-right: 15px;
}

.bookshelf .row + div {
	margin-top: 40px;
}


/* These styles are for previewing only and should be */
/* replaced / deleted when your slider plugin is added.  */

.bookshelf .row {
	margin-right: -40px;
}

.adultshelf {
	background-image: url(../img/book-strip-adult.png);
	height: 250px;
	width: 100%;
}

.kidsshelf {
	background-image: url(../img/book-strip-kids.png);
	height: 250px;
	width: 100%;
}


/*-----------------------------------*/
/*-- Agent Styles --*/
/*-----------------------------------*/

.divider {
	width: 15%;
	vertical-align: top;
	margin-left: 15%;
	margin-right: 0px;

}

.portrait {
	border: 2px solid #cccccc;
	width:40%;
}

@media (max-width: 630px) {
	.divider {
		-ms-transform: rotate(-90deg); /* IE 9 */
		-webkit-transform: rotate(-90deg); /* Safari */
		transform: rotate(-90deg);
		position: relative;
		top: 12vh;
		left: -90px;
		width: 8%;

	}
	.portrait {
		width: 30%;
	}
}

@media (max-width: 400px) {
	.divider {
		-ms-transform: rotate(-90deg); /* IE 9 */
		-webkit-transform: rotate(-90deg); /* Safari */
		transform: rotate(-90deg);
		position: relative;
		top: 16vh;
		left: -90px;
		width: 12%;

	}
	.portrait {
		width: 50%;
	}
}


/*-----------------------------------*/
/*-- Button Styles --*/
/*-----------------------------------*/

.btn {
	color: #333;
	background: #e3dbcb url(../img/wood-2.jpg) center;
	background-clip: padding-box;
	font-size: 1.2em;
	padding: 12px 22px 12px 22px;
	border-radius: 0;
	font-weight: bold;
	vertical-align: bottom;
	border-top: 1px solid #817c6d;
	border-bottom: 1px solid #817c6d;
	margin-left: -10px;
	margin-right: -10px;
	max-width: 80vw;
	overflow: hidden;
}
select.btn {
	background: transparent;
	padding: 5px;
	font-size: 16px;
	line-height: 1;
	border: 0;
	border-radius: 0;
	height: 34px;
	-webkit-appearance: none;
}

.btn a, a:visited {
	color: #333;
}

.btn:hover {
	color: #000;
	background: url(../img/wood-2hover.jpg) center;
}

.btn img {
	width: 40px;
	height: 40px;
	vertical-align: central;
}

.btn-wrapper {
	margin-right: 40px;
	margin-bottom: 10px;
	display:inline-block;
} /* Used to create space between the inline buttons since margin and padding are already used. */

.btn-borders {
	padding: 6px 0px 6px 0px;
	border-top: 1px solid #817c6d;
	border-bottom: 1px solid #817c6d;
	text-align: center;
}

.btn-borders .btn-block {
	width: auto;
}

@media (min-width: 400px) {
	.btn-block {
		max-width: 100vw;
	}
	.btn-borders .btn-block {
		/*margin-top: 20px;*/
	}
}

.alt {
	background: #e3dbcb;
	text-transform: uppercase;
} /* Allcaps button without a wood background */

.alt:hover {
	background-color: #004b29;
}

.disabled {
	color: #555;
	background-color: #ccc;
	border: 1px solid #aaa;
}

.dropdown, .dropdown-menu, .dropdown-toggle {
	text-align:left;
	margin: 10px 0px 0px 0px;
	position: relative;
	font-size:18px;
}

.dropdown .btn {
		background: #e3dbcb;
}

.upload {
	margin-top: 60px;
}



/*-----------------------------------*/
/*-- List Styling --*/
/*-----------------------------------*/

li {
	padding-bottom: .8em;
	padding-top: .4em;
}

.fancy {
	list-style-image:url(../img/leaf2_sm.png);
}

/*-----------------------------------*/
/*-- Form Styling --*/
/*-----------------------------------*/

.submission-header {
	text-align: center;
	height: 90px;
}

.submission-header h1 {
	color: #009045;
	font-style: italic;
	font-weight: normal;
}

.submission-header img {
	width: 70px;
	position: relative;
	top: -65px;
}

.submission-header hr {
	margin-top: 38px;
}

input {
	padding: 16px 10px 10px 10px;
	margin: 10px 10px 10px 0px !important;
	color: #000;
	font-size: 1.2em;
	}

form {
	width: inherit;
}

.form-inline input {
	width: 100%;
}

select {
	padding: 16px 10px 10px 10px;
	margin: 10px 0px 10px 0px;
}

form label {
	margin-top: 28px;
	padding-left: 0 !important;
	font-size: 1.125em;
}

.form-control {
	margin: 0px !important;
	color: green;
	height: inherit;
	padding: 12px 22px 12px 22px;
	font-size: 1.125em;
}

.input-group-btn {
	font-size: .9em;
}

.input-group-btn .btn {
	margin-left: 20px !important;
	border-right: 0px;
	border-left: 0px;
}

.input-group input {
	width: inherit;
}

.form-note {
	color: #009045;
	font-family: 'Amiri', Georgia, serif;
	font-style:italic;
	font-size: 1.3em;
	line-spacing: 1.5em;
}

.error {
	border: 2px solid #a94442;
	border-style:solid !important;
	background-color: white;
}
/* Styles fields with a red outline for error, can be used in conjunction with alert-danger messages and styling */


/*-----------------------------------*/
/*-- Horizontal Rule Styling --*/
/*-----------------------------------*/

hr {
	border-top: 1px solid #cacaca;
	margin-top: 30px;
}

.gray-hr {
	text-align: center;
	vertical-align: top;
	margin-top: 30px;
}

.gray-hr img {
	position: relative;
	top: -55px;
	width: 70px;
	background: #fff;
	border: 12px solid #fff;
}

.short-hr {
	text-align: center;
	vertical-align: top;
	margin-top: 30px;
	width: 100%;
	padding: 0em 15em 0em 15em;
}

.short-hr hr {
	border-top: 2px solid #267242;
}

.short-hr img {
	position: relative;
	top: -55px;
	width: 70px;
	background: #fff;
	border: 12px solid #fff;
}


/*-----------------------------------*/
/*-- Table Styling --*/
/*-----------------------------------*/

td {
	padding: 10px;
	border-bottom: 1px solid #ccc;
	font-weight: normal !important;
}

thead {
	color: #267242;
	border-bottom: 1px solid #333;
}

tfoot, tfoot th {
	font-size: .9em;
	border-bottom: 1px solid #222 !important;
	color: #888;
	font-weight: normal !important;
}

/* Firefox hack for responsive tables recommended by Bootstrap */
@-moz-document url-prefix() {
	fieldset { display: table-cell; }
}



/*-----------------------------------*/
/*-- Watermark Styling --*/
/*-----------------------------------*/

.page-header-watermark {
	background: url(../img/background-watermark2.svg) no-repeat center top;
	background-size: 650px;
	min-height: 400px;
	padding-top: 50px;
	margin-bottom: 80px;
}

.page-footer-watermark {
	background: url(../img/background-footer-watermark2.svg) no-repeat center bottom;
	background-size: 512px;
}

/*-----------------------------------*/
/*-- Footer Styling --*/
/*-----------------------------------*/

.poetry {
	padding: 10vh 0px;
	font-family: 'Amiri', Georgia, serif;
	font-style: italic;
	color: #267242;
	font-size: .9em;
}

.mainfooter {
	background-color: #ecf2f0;
	margin-top: 0px;
	padding-top: 80px;
	padding-bottom: 80px;
	border-top: .5vh solid #b4d2c8;
	border-bottom: 1vh solid #267242;
}

.mainfooter ul {
	margin: 40px 0px;
}

.connect {
	margin: 40px 0px;
	text-align: center;
	height: 220px;
	border-right: 1px solid #B4D1C3;
}

.connect img {
	width: 80px;
	padding: 75px 10px;
}
.connect img:hover {
	filter:contrast(200%);
}

.mainfooter .brand {
	text-align: center;
}
.mainfooter .brand img {
	padding: 20px;
	width: 30%;
}
.mainfooter .logo {
	width: 100%;
}
.mainfooter .brand img.logo {
    width: 60%;
}
@media (min-width: 1400px) {
	.mainfooter .logo {
		width: 40%;
	}
}
.footernav {
	margin: 40px 0px;
	height: 220px;
	border-left: 1px solid #B4D1C3;
}

.mainfooter .nav a {
	font-weight: normal;
	padding: 5px;
	color: #333;
	font-size: .9em;
}
.mainfooter .nav li {
	padding-top: .4em;
	padding-bottom: .4em;
}

@media (max-width: 950px) {
	.connect img {
		padding: 10px 10px;
	}
	.mainfooter .nav a {
		font-size: .7em;
	}
	.mainfooter .nav .col-sm-6 {
		padding-left: 0px;
		padding-right: 0px;
	}
	.mainfooter .nav .col-sm-12 {
		padding-left: 0px;
		padding-right: 0px;
	}
}
@media (max-width: 600px) {
	.mainfooter nav, .footernav {
		display: none;
	}
	.connect {
		border-right: 0px;
		margin: 0px;
		height: 150px;
	}
}

.subfooter {
	background: #333 url(../img/diamond-background.png);
	color: #DAE8E1;
	font-size: .75em;
	text-transform: uppercase;
	font-weight: normal;
	letter-spacing: .1em;
	border-bottom: 3vh solid #111;
}

.contactinfo {
	text-align: right;
}

@media (max-width: 600px) {
	.subfooter {
		text-align: center;
	}
	.contactinfo {
		text-align: center;
	}
}

@media only screen and (max-width: 768px) {
	video {
		display:none;
	}
	.hero-home {
		background: url(../img/prospect-park-still.jpg) no-repeat 50% 80%;
	}
}
