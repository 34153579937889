video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
}

.bookshelf {
  overflow-x: hidden;
  position: relative;
}

.list {
  width: 10000px;
  position: relative;
  overflow-x: hidden;
  height: 250px;
  .sublist {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .book {
    display: inline-block;
    margin-right: 15px;
    img {
      height: 250px;
      width: auto;
    }
  }
}

.book{
  cursor:grab;
}

.client-card{
  padding: 10px;
  background-color: #ecf2f0;
  margin-bottom: 20px;

  h3{
    margin: 5px 0;
  }
}

.subheader{
      border-bottom: .7vh solid #267242;
}


.firts-paragraph{

  margin: 20px 0;
  p{
    text-align: justify;

  }
}


.box{
  padding: 25px 70px;
  background: $pearl_bush;
  margin: 20px 0;
  font-size: 17px;
  font-weight: 100;
  a{
    color:$moss;
    font-weight: 100;
  }
}




.faq{

   img{
          width: 65px;
          height: 65px;
          vertical-align: middle;



      }

  h3{


  }


  p{
    /*padding-left: 90px;*/
    a{
      color:$moss;
      font-weight: 100;
    }
  }

  ul{
    li{
      padding:0px 0 10px 0;
    }
  }
}

.question{
  margin: 20px 0 30px 0;
}


label.error{
  font-size: 12px !important;
  color:red;
  border:none !important;
  margin:0px;
  padding: 0px;
}

.no-click{
  a{
    color:gray !important;
    pointer-events: none;
  }
}
