.carousel-control{
    background-image: none !important;
}

.carousel-indicators{
    ol{

    }

    li{
            padding-bottom:0 !important;
            padding-top:0 !important;
        }
}